$(document).ready(function () {
    let $input = $('.number-spinner input[type=text]');
    let $total = $('.details__option_total .value');
    let price = parseFloat($('.details__option_price .price').html());
    let currency =  $('.details__option_price .currency').html();
    let bonusType = $('.details__bonus').data('bonus-type');



    $input.on('input', function() {
        let totalPrice = this.value * price;
        if (bonusType === 'discount') {
            if (this.value >= 3 && this.value < 7) {
                totalPrice = totalPrice * 0.95;
            }
            if (this.value >= 7 && this.value < 10) {
                totalPrice = totalPrice * 0.9;
            }
            if (this.value >=10 && this.value <= 12) {
                totalPrice = totalPrice * 0.85;
            }
        }
        $total.html(+ (totalPrice).toFixed(1) + ' ' + currency)
    });
});