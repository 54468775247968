$(document).ready(function () {
    const monthPluralize = function(count){
        if(count === 1){
            return 'месяц'
        }
        if(count < 5){
            return 'месяца'
        }
        return 'месяцев'
    };
    $spinnerEl = $('.number-spinner');
    $spinnerEl.each(function(){
        $mounthText = $(this).find('.month-text');
        $input = $(this).find('input[type=text]');
        $(this).find('.up').on('click', function () {
            let quantity = parseInt($input.val()) + 1;
            if(quantity > 12) {
                quantity = 12;
            }
            $input.val(quantity);
            $input.trigger('input');
        });
        $(this).find('.down').on('click', function () {
            let quantity = parseInt($input.val()) - 1;
            if(quantity < 1) {
                quantity = 1;
            }
            $input.val(quantity);
            $input.trigger('input');
        });
        $input.on('input', function () {
            $mounthText.html(monthPluralize(parseInt(this.value)));
            let $bonusScope = $('.details__bonus');
            let bonusType = $bonusScope.data('bonus-type');
            let bonusTexts = [
                'Вы получаете 1 месяц дополнительно!',
                'Вы получаете 2 месяца дополнительно!',
                'Вы получаете 3 месяца дополнительно!'
            ];
            if (bonusType === 'discount') {
                bonusTexts = [
                    'Вы получаете скидку 5%',
                    'Вы получаете скидку 10%',
                    'Вы получаете скидку 15%',
                ];
            }
            if (this.value < 3) {
                $bonusScope.removeClass('text_color_blue');
                $bonusScope.find('.text-container').text('При заказе от трёх месяцев — бонус!')
            }
            if (this.value >=3 && this.value < 7) {
                $bonusScope.addClass('text_color_blue');
                $bonusScope.find('.text-container').text(bonusTexts[0]);
            }
            if (this.value >=7 && this.value < 10) {
                $bonusScope.addClass('text_color_blue');
                $bonusScope.find('.text-container').text(bonusTexts[1]);
            }
            if (this.value >=10) {
                $bonusScope.addClass('text_color_blue');
                $bonusScope.find('.text-container').text(bonusTexts[2]);
            }
        })
    })
});