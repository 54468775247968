$(document).ready(function () {
    $('body').on('click', function(e){
        $('.input-group__select').removeClass('input-group__select--opened');
        $('.input-group__select-value-arrow').removeClass('fa-chevron-up');
        $('.input-group__select-value-arrow').addClass('fa-chevron-down');
    });
    $('.input-group__select-value').click(function (e) {
        e.stopPropagation();
        var $inputSelector = $(this).parents('.input-group__select');
        var $chevronArrow = $inputSelector.find('.input-group__select-value-arrow');
        if ($inputSelector.hasClass('input-group__select--opened')) {
            $inputSelector.removeClass('input-group__select--opened');
            $chevronArrow.removeClass('fa-chevron-up');
            $chevronArrow.addClass('fa-chevron-down');
        } else {
            $inputSelector.addClass('input-group__select--opened');
            $chevronArrow.removeClass('fa-chevron-down');
            $chevronArrow.addClass('fa-chevron-up');
        }
    });
    $('.input-group__select-value-list-item').click(function () {
        var $inputSelector = $(this).parents('.input-group__select');
        var itemImage = $(this).data('flag');
        if (itemImage) {
            $inputSelector.find('.input-group__select-value-image').attr('src', itemImage);
        }
        var itemCountry = $(this).data('country');
        if (itemCountry) {
            $inputSelector.find('.input-group__select-input-country').val(itemCountry);
            if(itemCountry === 'OTHER') {
                $('#js-phone-description').html('Введите номер в международном формате, без плюса.<br> Например: 444 999 888 77 66')
            }
            if(itemCountry === 'RU'){
                $('#js-phone-description').html('Введите номер без плюса и кода страны.<br> Например: 495 777 55 88')
            }
            if(itemCountry === 'UA'){
                $('#js-phone-description').html('Введите номер без плюса и кода страны.<br> Например: 67 222 11 55')
            }
            if(itemCountry === 'BY'){
                $('#js-phone-description').html('Введите номер без плюса и кода страны.<br> Например: 29 555 44 33')
            }
        }
        var itemValue = $(this).data('value');
        if (itemValue) {
            $inputSelector.find('.input-group__select-value-text').text(itemValue);
            $inputSelector.find('.input-group__select-input .input').val(itemValue);
        }

        $('.input-group__select-value-list-item').each(function () {
            $(this).removeClass('input-group__select-value-list-item--active');
        });
        $(this).addClass('input-group__select-value-list-item--active');

    });
    $('#js-generate-password').on('click', function () {
        var randomPassword = Math.random().toString(36).slice(-8);
        $('#passwordInput').attr('type', 'text').val(randomPassword);
    });

    initialiseCheckboxes();
});

function initialiseCheckboxes() {
    $('.input-group__select').each(function () {
        var $inputSelect = $(this);
        var settedValue = $inputSelect.find('.input-group__select-input .input').val();
        if (settedValue) {
            var valueFound = false;
            $inputSelect.find('.input-group__select-value-list-item').each(function () {
               var value = $(this).data('value');
               if (value === settedValue) {
                   $(this).click();
                   valueFound = true;
               }
            });
        }
    })
}